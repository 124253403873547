<script lang="ts" setup>
import type { FilterConditionValue } from '~~/types'

const props = withDefaults(defineProps<{
  modelValue: number | string
}>(), {
  modelValue: '',
})

const emit = defineEmits<{
  'update:modelValue': [FilterConditionValue]
}>()

const inputValue = ref(props.modelValue)
</script>

<template>
  <div class="flex items-stretch hover:bg-gray-100">
    <DocumentsFilterInputPopover @submit="$emit('update:modelValue', inputValue)">
      <template #button>
        <span :class="{ 'text-gray-400': !modelValue }">
          {{ modelValue || $t('documents.filters.value.input') }}
        </span>
      </template>
      <DTFieldInput
        v-model.number="inputValue"
        type="number"
        size-variant="xs"
        required
        @blur="emit('update:modelValue', inputValue)"
      />
    </DocumentsFilterInputPopover>
  </div>
</template>
