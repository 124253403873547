<script lang="ts" setup>
import type { FilterConditionValue } from '~~/types'

const props = withDefaults(defineProps<{
  modelValue: boolean
}>(), {
  modelValue: false,
})

const emit = defineEmits<{
  'update:modelValue': [FilterConditionValue]
}>()

const modelValue = useVModel(props, 'modelValue', emit)
</script>

<template>
  <div class="flex items-center px-0.5 hover:bg-gray-100">
    <DTCheck
      v-model="modelValue"
      size="sm"
    />
  </div>
</template>
