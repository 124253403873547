import type { Document, Folder } from '~/types'

export type SelectableDocumentsItem = Document | Folder

export const useDocumentsMultiSelection = () => {
  const selection = useState<SelectableDocumentsItem[]>('documents-multiselection', () => [])

  const isItemSelected = (item: SelectableDocumentsItem) =>
    selection.value.some(({ id, _type }) => item.id === id && item._type === _type)

  const toggle = (item: SelectableDocumentsItem) => {
    if (isItemSelected(item))
      selection.value = selection.value.filter(({ id, _type }) => item._type !== _type ? true : item.id !== id)
    else
      selection.value.push(item)
  }

  return {
    isItemSelected,
    selection,
    toggle,
  }
}
