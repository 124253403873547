import isEqual from 'lodash.isequal'
import type { FilterCondition, FilterConfig, FilterData, FilterEntity, FilterEntityField, FilterOperator, FilterOptionsCommon, FilterValueType, PropertyData } from '~/types'
import DocumentsFilterValueDate from '~/components/Documents/Filter/DocumentsFilterValueDate.vue'
import DocumentsFilterValueString from '~/components/Documents/Filter/DocumentsFilterValueString.vue'
import DocumentsFilterValueNumeric from '~/components/Documents/Filter/DocumentsFilterValueNumeric.vue'
import DocumentsFilterValueBoolean from '~/components/Documents/Filter/DocumentsFilterValueBoolean.vue'

// operators that should not have a value
export const filterOperatorsWithoutValue: FilterOperator[] = ['is_empty', 'is_not_empty']

// all common operators
export const filterOperatorsCommon: FilterOperator[] = [...filterOperatorsWithoutValue]

// string operators
export const filterOperatorsString: FilterOperator[] = ['contains', 'not_contains', 'is', 'is_not']

// numeric and date operators
export const filterOperatorsScalar: FilterOperator[] = ['gte', 'lte']

// boolean operators
export const filterOperatorsBoolean: FilterOperator[] = []

// operators for multiple values
export const filterOperatorsMultiSelect: FilterOperator[] = ['is_any_of', 'is_none_of']

export const isFilterValueRequired = (operator: FilterOperator) =>
  !filterOperatorsWithoutValue.includes(operator)

export const isValidFilterCondition = ({ operator, value }: FilterCondition) => {
  if (!operator)
    return false

  if (isFilterValueRequired(operator)) {
    if (typeof value === 'number' || typeof value === 'boolean')
      return true

    else if (Array.isArray(value))
      return !!value.length

    else
      return !!value
  }
  else {
    return true
  }
}

export const sanitizeFiltersForPayload = (filters: FilterData[]): FilterData[] =>
  filters.filter(filter =>
    filter.rules.length
    && filter.rules.every(rule => rule.conditions.length && rule.conditions.every(isValidFilterCondition)))

export const prepareFilterDataForPayload = (
  filter: FilterData,
): App.Data.Payloads.Views.FilterPayload => ({
  clause: filter.clause,
  rules: filter.rules.map(rule => (
    {
      entity: rule.entity,
      conditions: rule.conditions.map((condition) => {
        const value = isFilterValueRequired(condition.operator)
          ? (condition.value ?? undefined)
          : undefined

        return {
          field: condition.field,
          operator: condition.operator,
          value,
        }
      }),
    }
  )),
  meta: filter.meta || undefined,
})

export const transformFiltersToFiltersPayload = (
  filters: FilterData[],
): App.Data.Payloads.Views.FilterPayload[] =>
  sanitizeFiltersForPayload(filters).map(prepareFilterDataForPayload)

export const isEqualFiltersIfSanitized = (a: FilterData[], b: FilterData[]) =>
  isEqual(sanitizeFiltersForPayload(a), sanitizeFiltersForPayload(b))

export const defineFilterConfig = <ValueT>(opts: FilterConfig<ValueT>): FilterConfig<ValueT> => opts

export const serializeFilterDataConditions = (
  filter: FilterData,
) => filter.rules.map((rule) => {
  const conditions = rule.conditions.map(condition => condition.field).join(',')
  return `${rule.entity}:${conditions}`
}).join('&&')

// Internal helper for single condition filters
export const matchSerializedFilterEntityField = (str: string) => (filter: FilterData): boolean =>
  str === serializeFilterDataConditions(filter)

// Get default operator based on value
export const getDefaultOperator = (valueType: FilterValueType): FilterOperator => {
  switch (valueType) {
    case 'string': return 'contains'
    case 'numeric':
    case 'date': return 'gte'
    case 'boolean': return 'is'
    case 'select':
    default: return 'is_any_of'
  }
}

export const getDefaultValue = (valueType: FilterValueType) => {
  switch (valueType) {
    case 'date':
    case 'numeric':
    case 'string': return ''
    case 'boolean': return true
    case 'select': return []
  }
}

/** Creates the most common filter configuration where `FilterEntity` and `FilterField` are always static inside a single `FilterCondition`. */
export const defineSingleConditionFilter = <E extends FilterEntity>(
  entity: E,
  field: FilterEntityField[E],
  opts: FilterOptionsCommon,
): FilterConfig => defineFilterConfig({
  ...opts,
  matchToFilterData: matchSerializedFilterEntityField(`${entity}:${field}`),
  createFilterData: (
    operator = getDefaultOperator(opts.valueType),
    value = getDefaultValue(opts.valueType),
  ) => ({
    clause: 'where_all',
    _type: 'filter',
    meta: {},
    rules: [
      {
        _type: 'rule',
        entity,
        conditions: [
          {
            _type: 'condition',
            field,
            operator,
            value: value ?? null,
          },
        ],
      },
    ],
  }),
  getOperator: filter => filter.rules[0].conditions[0].operator,
  getValue: filter => filter.rules[0].conditions[0].value,
})

// eslint-disable-next-line style/max-len
/** Creates a filter configuration for a `PropertyData`. This would work for any entity that has `stringValues`, `numericValues`, `dateValues`, `booleanValues`, metadata relations. Currently only relevant for `Document.MetaData` though. */
export const defineDocumentMetadataPropertyFilter = ({
  data_type,
  title,
  description,
  id,
}: PropertyData): FilterConfig => {
  const entity = createDocumentMetadataValueFilterEntity(data_type)
  return defineFilterConfig({
    valueComponent: ({
      string: DocumentsFilterValueString,
      numeric: DocumentsFilterValueNumeric,
      date: DocumentsFilterValueDate,
      boolean: DocumentsFilterValueBoolean,
    } as Record<typeof data_type, FilterConfig['valueComponent']>)[data_type],
    valueType: data_type,
    title,
    description: description ?? undefined,
    matchToFilterData: ({ rules }) =>
      rules.length === 1
      && rules[0].conditions.length === 2
      && rules[0].conditions.some(c => c.field === 'property_id' && Array.isArray(c.value) && c.value.includes(id)),
    createFilterData: (
      operator = getDefaultOperator(data_type),
      value = getDefaultValue(data_type),
    ) => ({
      clause: 'where_all',
      _type: 'filter',
      meta: {},
      rules: [
        {
          _type: 'rule',
          entity,
          conditions: [
            {
              field: 'property_id',
              operator: 'is_any_of',
              value: [id],
              _type: 'condition',
            },
            {
              field: 'value',
              operator,
              value: data_type === 'boolean' ? value || false : value,
              _type: 'condition',
            },
          ],
        },
      ],
    }),
    getOperator: filterData => data_type === 'boolean'
      ? 'is'
      : filterData.rules[0]!.conditions.find(c => c.field === 'value')!.operator,
    getValue: filterData => data_type === 'boolean'
      ? filterData.rules[0]!.conditions.find(c => c.field === 'value')!.value || false
      : filterData.rules[0]!.conditions.find(c => c.field === 'value')!.value,
  })
}
