<script lang="ts" setup>
import DTPopover from '@docue/docue-ui-v2/ui-components/DTPopover/DTPopover.vue'

const emit = defineEmits<{
  submit: []
}>()

const popover = ref<InstanceType<typeof DTPopover>>()
const close = () => popover.value?.trigger()?.click()
const submit = () => {
  emit('submit')
  nextTick(() => close())
}
</script>

<template>
  <DTPopover
    ref="popover"
    placement="bottom-start"
  >
    <template #button>
      <DTPopoverButton
        class="flex h-full items-center px-1 text-xs text-gray-600 hover:bg-gray-100"
      >
        <slot name="button" />
      </DTPopoverButton>
    </template>
    <form
      class="flex w-64 flex-col gap-4 rounded-xl p-4"
      @submit.prevent="submit"
    >
      <slot />
      <div class="flex items-center justify-end gap-2">
        <DTButton
          size="xs"
          type="reset"
          @click="close"
        >
          {{ $t('cancel') }}
        </DTButton>
        <DTButton
          size="xs"
          variant="blue"
          type="submit"
        >
          {{ $t('apply') }}
        </DTButton>
      </div>
    </form>
  </DTPopover>
</template>
