<script lang="ts" setup>
import dayjs from 'dayjs'
import LineChevronDown from '@docue/docue-ui-v2/icons/LineChevronDown.vue'

const { t } = useI18n()
const { formatDate } = useIntl()

const modelValue = defineModel({ default: '' })
const isInitiallyRelative = computed(() => /^P|^-P/.test(modelValue.value))
const tab = ref<'fixed' | 'relative'>(isInitiallyRelative.value ? 'relative' : 'fixed')
const initialRelativeDays = computed(() => isInitiallyRelative.value ? dayjs.duration(modelValue.value).days() : '')
const fixedDate = ref(isInitiallyRelative.value ? '' : modelValue.value)
const relativeDays = ref(initialRelativeDays.value)
const relativeDirection = ref<'past' | 'future'>(modelValue.value.startsWith('-') ? 'past' : 'future')

const displayText = computed(() => modelValue.value
  ? isInitiallyRelative.value
    ? relativeDirection.value === 'past'
      ? t('documents-filter-value-date.days-in-the-past', { days: initialRelativeDays.value })
      : t('documents-filter-value-date.days-in-the-future', { days: initialRelativeDays.value })
    : formatDate(modelValue.value)
  : t('documents-filter-value-date.select-value'))

const submit = () =>
  modelValue.value = tab.value === 'relative'
    ? `${relativeDirection.value === 'past' ? '-' : ''}P${relativeDays.value}D`
    : fixedDate.value
</script>

<template>
  <div class="flex items-stretch hover:bg-gray-100">
    <DocumentsFilterInputPopover @submit="submit">
      <template #button>
        <span :class="{ 'text-gray-400': !modelValue }">
          {{ displayText }}
        </span>
      </template>
      <div
        class="grid grid-cols-2 items-stretch gap-1 rounded-lg bg-gray-100 p-1"
      >
        <DTButton
          :variant="tab === 'fixed' ? 'default' : 'plain'"
          :class="tab === 'fixed' ? 'hover:bg-white hover:cursor-default' : ''"
          size="xs"
          @click="tab = 'fixed'"
        >
          {{ t('documents-filter-value-date.tab-fixed') }}
        </DTButton>
        <DTButton
          :variant="tab === 'relative' ? 'default' : 'plain'"
          :class="tab === 'relative' ? 'hover:bg-white hover:cursor-default' : ''"
          size="xs"
          @click="tab = 'relative'"
        >
          {{ t('documents-filter-value-date.tab-relative') }}
        </DTButton>
      </div>
      <div
        v-if="tab === 'fixed'"
        class="flex flex-col"
      >
        <DTFieldInput
          v-model="fixedDate"
          type="date"
          size-variant="xs"
          class="text-left text-gray-600 !outline-offset-2"
          required
        />
      </div>
      <div
        v-else-if="tab === 'relative'"
        class="flex flex-col gap-2"
      >
        <DTFieldInputWrapper>
          <DTFieldInput
            v-model="relativeDays"
            type="number"
            size-variant="xs"
            class="text-left text-gray-600 !outline-offset-2"
            required
          />
          <DTFieldInputInlineAddon>
            {{ t('documents-filter-value-date.days') }}
          </DTFieldInputInlineAddon>
        </DTFieldInputWrapper>
        <div class="relative flex flex-col">
          <select
            v-model="relativeDirection"
            class="appearance-none rounded-md border border-gray-300 px-3 py-2 text-xs"
          >
            <option value="past">
              {{ t('documents-filter-value-date.label-past') }}
            </option>
            <option value="future">
              {{ t('documents-filter-value-date.label-future') }}
            </option>
          </select>
          <LineChevronDown class="absolute right-3 top-2.5 size-4" />
        </div>
      </div>
    </DocumentsFilterInputPopover>
  </div>
</template>
