import type { CollectionResponseMeta } from '~/types/api/v2'
import type { Document, DocumentPayload, FilterData, Folder, FolderPayload } from '~/types'

const { workspace, activeWorkspaceId } = useAuth()

export const useDocumentsItemSelection = <T extends Document | Folder = Document | Folder>() => useState<T | undefined>()
export const useCurrentFolder = () => useState<Folder | undefined>()
export const useRootFolder = () => useState<Folder | undefined>()
export const useDocumentListPagination = () => useState<CollectionResponseMeta | undefined>()

export const documentsDataLoading = ref(false)

export const rootFolderId = computed(() => workspace.value?.attributes.root_folder_id)

const documentListPagination = useDocumentListPagination()
const folderList = useFolderList()
const documentList = useDocumentList()
const route = useRoute()

const { currentView } = useViews()

export const documentsSortStorageKey = computed(() => `${activeWorkspaceId.value}::folder::sort`)
export const documentsFiltersStorageKey = computed(
  () => `${activeWorkspaceId.value}::folder::filters`,
)
export const documentsFiltersIncludeSubfoldersStorageKey = computed(
  () => `${activeWorkspaceId.value}::folder::subfolders`,
)

const documentsSort = useSessionStorage(documentsSortStorageKey.value, DEFAULT_DOCUMENTS_SORT)
const documentsFilters = useSessionStorage<FilterData[]>(documentsFiltersStorageKey.value, [])
const documentsIncludeSubfolders = useSessionStorage<boolean>(documentsFiltersIncludeSubfoldersStorageKey.value, true)

watch(() => route.path, (p) => {
  if (p === '/2/documents' || p.startsWith('/2/documents/f/'))
    documentsDataLoading.value = true

  folderList.value = []
  documentList.value = []
  documentListPagination.value = undefined
})

export const documentsData = computed(
  () => [...(folderList.value ?? []), ...(documentList.value ?? [])],
)

export const canFetchMoreDocuments = computed(() => {
  if (!documentListPagination.value)
    return false

  const { current_page, last_page } = documentListPagination.value!
  return current_page < last_page
})

export const clearDocumentsData = () => {
  documentList.value = []
  folderList.value = []
  documentListPagination.value = undefined
}

const latestDocumentsDataRequestId = ref<string>()

export const fetchDocumentsData = async () => {
  // Hack to avoid race condition between page changes etc. because
  // our fetch requests cannot be aborted. (Not implemented in nuxt)
  // This approach only refuses to update stale data but still allows the
  // redundant request to happen.
  const requestId = crypto.randomUUID()
  latestDocumentsDataRequestId.value = requestId

  const folderId = currentView.value ? null : useCurrentFolder().value?.id || rootFolderId.value

  documentsDataLoading.value = true

  const requests = await Promise.allSettled([
    (async () => {
      if (!folderId)
        return

      // If subfolders are included in document list filtering page
      // no need to fetch folders
      if (!currentView.value && documentsFilters.value.length && documentsIncludeSubfolders.value) {
        folderList.value = []
        return
      }

      // only sort folders by name but take the order into account
      const sort = documentsSort.value.startsWith('-') ? '-name' : 'name'

      const res = await useApi().fetchFolders(folderId)({ params: { sort } })

      if (requestId === latestDocumentsDataRequestId.value)
        folderList.value = res.data
    })(),
    (async () => {
      const params = {
        page: 1,
        per_page: DEFAULT_DOCUMENTS_PER_PAGE,
      }

      const filters = currentView.value?.filters
        ?? documentsFilters.value

      const sort = currentView.value?.sort
        ?? documentsSort.value

      const subfoldersAreIncluded = documentsIncludeSubfolders.value && filters.length

      const folderFilterPayload: App.Data.Payloads.Views.FilterPayload | null
        = folderId
          ? {
              clause: 'where_all',
              rules: [
                {
                  entity: subfoldersAreIncluded ? 'document.folder.ancestor' : 'document',
                  conditions: [{
                    field: subfoldersAreIncluded ? 'id' : 'folder_id',
                    operator: 'is_any_of',
                    value: [folderId],
                  }],
                },
              ],
            }
          : null

      const payload = {
        filters: transformFiltersToFiltersPayload(filters).concat(folderFilterPayload || []),
        sort,
      }

      const { data, meta } = await useApi().searchDocuments(payload, { params })

      if (requestId === latestDocumentsDataRequestId.value) {
        documentList.value = data
        documentListPagination.value = meta
      }
    })(),
  ])

  requests.forEach((entry) => {
    if (entry.status === 'rejected')
      console.error(entry.reason)
  })

  documentsDataLoading.value = false

  return requests
}

watch(documentsDataInvalidationTicker, () => {
  fetchDocumentsData()
})

export const loadingMoreDocumentsData = ref(false)
export const fetchMoreDocumentsData = async () => {
  if (loadingMoreDocumentsData.value || !canFetchMoreDocuments.value)
    return

  loadingMoreDocumentsData.value = true

  const params = {
    page: documentListPagination.value!.current_page + 1,
    per_page: documentListPagination.value!.per_page,
  }

  const folderId = currentView.value
    ? null
    : useCurrentFolder().value?.id || rootFolderId.value

  try {
    const filters = currentView.value?.filters
      ?? documentsFilters.value

    const sort = currentView.value?.sort
      ?? documentsSort.value

    const subfoldersAreIncluded = documentsIncludeSubfolders.value && filters.length

    const folderFilterPayload: App.Data.Payloads.Views.FilterPayload | null
      = folderId
        ? {
            clause: 'where_all',
            rules: [
              {
                entity: subfoldersAreIncluded ? 'document.folder.ancestor' : 'document',
                conditions: [{
                  field: subfoldersAreIncluded ? 'id' : 'folder_id',
                  operator: 'is_any_of',
                  value: [folderId],
                }],
              },
            ],
          }
        : null

    const payload = {
      filters: transformFiltersToFiltersPayload(filters).concat(folderFilterPayload || []),
      sort,
    }

    const res = await useApi().searchDocuments(payload, { params })
    documentList.value = documentList.value?.concat(res.data)
    documentListPagination.value = res.meta
  }
  catch (e) {
    console.error(e)
  }
  finally {
    loadingMoreDocumentsData.value = false
  }
}

export const updateFolder = async (folder: FolderPayload & { parent_id?: string }) => {
  const updated = await useApi().updateFolder(folder)().then(r => r.data)
  const currentFolderId = useCurrentFolder().value?.id || rootFolderId.value

  /**
   * Delete the folder if it is moved out of current folder
   */
  if (!currentView.value && currentFolderId
    && folder.parent_id && currentFolderId !== folder.parent_id) {
    folderList.value = [...(folderList.value ?? [])].filter(f => f.id !== updated.id)
  }
  else {
    folderList.value = [...(folderList.value ?? [])].map((f) => {
      if (f.id === updated.id)
        return updated

      return f
    })
  }

  const current = useCurrentFolder()
  if (updated.id === current.value?.id)
    current.value = updated

  return updated
}

/**
 * Replace the document in the documents list with the new document.
 */
export const replaceDocument = (document: Document) => {
  if (!documentList.value)
    return

  documentList.value = documentList.value!.map((d) => {
    if (d.id === document.id)
      return document

    return d
  })
}

export const updateDocument = async (document: DocumentPayload) => {
  const currentFolderId = useCurrentFolder().value?.id || rootFolderId.value
  const updated = await useApi().updateDocument(document)().then(r => r.data)

  /**
   * Remove document from list if it is moved out of current folder
   */
  if (!currentView.value && currentFolderId
    && document.folder_id && currentFolderId !== document.folder_id) {
    documentList.value = [...(documentList.value ?? [])].filter(d => d.id !== updated.id)
  }

  else {
    replaceDocument(updated)
  }

  const currenctSelection = useDocumentsItemSelection().value
  const currentSelectionId
    = currenctSelection && currenctSelection?._type === 'document' && currenctSelection.id

  if (
    currentSelectionId
    && currentSelectionId === document.id
    && documentList.value.length
    && !documentList.value.some(({ id }) => id === document.id)
  ) {
    useDocumentsItemSelection().value = undefined
  }

  return updated
}

export const createFolder = async (folder: { parent_id: string, name: string }) => {
  const created = await useApi().createFolder(folder).then(r => r.data)
  const currentFolderId = useCurrentFolder().value?.id

  if (currentFolderId === folder.parent_id || (!currentFolderId && folder.parent_id === rootFolderId.value))
    folderList.value?.push(created)

  return created
}

const isDocumentPreviewPage = computed(() => route.name === '2-documents-d-documentId')

export const onFolderDeleted = (folder: Folder) => {
  const { selection: multiselection } = useDocumentsMultiSelection()
  folderList.value = folderList.value?.filter(f => f.id !== folder.id)

  const activeItem = useDocumentsItemSelection()
  if (activeItem.value?.id === folder.id)
    activeItem.value = useCurrentFolder().value

  if (multiselection.value.some(x => x._type === 'folder' && x.id === folder.id))
    multiselection.value = multiselection.value?.filter(x => x.id !== folder.id)

  const currentFolder = useCurrentFolder()
  if (currentFolder.value?.id === folder.id && folder.parent_id) {
    navigateTo(
      folder.parent_id === rootFolderId.value
        ? '/2/documents'
        : `/2/documents/f/${folder.parent_id}`,
      { replace: true },
    )
    currentFolder.value = folder.parent ?? undefined
  }
}

export const deleteFolder = async (folder: Folder) => {
  await useApi().deleteFolder(folder.id)
  onFolderDeleted(folder)
}

export const onDocumentDeleted = (documentId: string) => {
  const { selection: multiselection } = useDocumentsMultiSelection()
  documentList.value = documentList.value?.filter(d => d.id !== documentId)

  const activeItem = useDocumentsItemSelection()
  if (activeItem.value?.id === documentId)
    activeItem.value = useCurrentFolder().value

  if (multiselection.value.some(x => x._type === 'document' && x.id === documentId))
    multiselection.value = multiselection.value?.filter(x => x.id !== documentId)

  if (isDocumentPreviewPage.value && route.params.documentId === documentId)
    navigateTo('/2/documents')
}

export const deleteDocument = async (documentId: string) => {
  await useApi().deleteDocument(documentId)
  onDocumentDeleted(documentId)
}
