import type { BuiltInView, ViewData } from '~~/types'

type ViewStore = Record<string, ViewData>

const viewsById = ref<ViewStore>()
const allViews = computed(() => viewsById.value ? Object.values(viewsById.value) : undefined)

export const currentView = useState<ViewData | BuiltInView | undefined>()

export default function () {
  const loadAllViews = async () => {
    try {
      const res = await useApi().fetchViews({
        params: {
          per_page: 100,
        },
      })
      viewsById.value = (res.data ?? [])
        .reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {} as ViewStore)
    }
    catch (er) {
      viewsById.value ??= {}
    }

    await nextTick()
    return allViews.value
  }

  const loadView = async (id: string) => {
    const view = await useApi().fetchView(id).then(r => r.data)
    viewsById.value ||= {}
    viewsById.value[id] = view
    return view
  }

  const setView = (view: ViewData) => {
    viewsById.value ||= {}
    viewsById.value[view.id] = view
  }

  const deleteView = async (id: string) => {
    await useApi().deleteView(id)
    delete viewsById.value?.[id]
  }

  const getView = async (id: string) => {
    const cached = viewsById.value?.[id]
    if (!cached)
      return await loadView(id)
    return Promise.resolve(cached)
  }

  return {
    allViews: readonly(allViews),
    setView,
    getView,
    loadAllViews,
    deleteView,
    currentView,
  }
}
